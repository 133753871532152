export const CLASSES = "Classes"
export const PERFORMANCES = "Performances"
export const ABOUT_US = "About Us"
export const CHOREOGRAPHY = "Hire Us"
export const CONTACT_AND_SOCIALS = "Contact / Socials"

export const COVER_PICTURE_TITLE = "Bay Area Bhangra Arts";
export const COVER_PICTURE_SUBTEXT = "Preserving Tradition, Inspiring Future Dancers";

export const CLASSES_TITLE = "We are officially open for registration";
export const YOUTH_CLASSES = "Youth Classes";
export const YOUTH_CLASS_DESCRIPTION = "Our youth Bhangra classes are designed to ignite passion and energy in young dancers while teaching them the vibrant art of traditional Bhangra. Through fun, dynamic lessons, we inspire the next generation to embrace their cultural roots, build confidence, and enjoy the joy of dance. Whether they’re new to Bhangra or have some experience, our classes offer a supportive and exciting environment where every child can thrive and express themselves";
export const ADULT_CLASSES = "Adult Classes";
export const ADULT_CLASS_DESCRIPTION = "Discover the joy of Bhangra in our adult classes, where tradition meets fitness in an energetic and welcoming atmosphere. Perfect for beginners and experienced dancers alike, our sessions focus on mastering the authentic techniques of Bhangra while providing a fun, high-energy workout. Connect with a community of like-minded individuals, relieve stress, and embrace the vibrant culture of Bhangra as you dance your way to a healthier, happier you.";
export const CLASS_REGISTRATION_LINK = "https://forms.gle/WPPS6Y9FAucHQpQP8"
export const CLASS_REGISTRATION_BUTTON_TEXT = "Register for classes now"

export const PREVIOUS_PERFORMANCES_TITLE = "Previous Performances"

export const ABOUT_US_SLIDE_1_DESCRIPTION = "Bay Area Bhangra Arts was founded by Bay Area natives and UC Berkeley alumni, and is dedicated to preserving and promoting the vibrant art of traditional Bhangra. Our mission is simple: to celebrate Bhangra's rich heritage through expert dance education that inspires and empowers dancers of all ages. Whether you’re new to Bhangra or an experienced performer, our classes offer something for everyone. We provide a welcoming environment where youth can ignite their passion for dance, and adults can reconnect with their cultural roots or explore a new, exciting way to stay active."
export const GURSHAAN_SINGH = "Gurshaan Singh"
export const GURSHAAN_BIO = "Gurshaan began his bhangra journey at the age of five, learning bhangra sets with live instruments and vocals at local melas and transitioning to music performances at competitions across the country. Gurshaan has danced on Cal Bhangra, Gabroo Unit, and served as a co-captain of Hukam de Yakke, allowing him to develop a deep appreciation for the creative and traditional elements of bhangra that create lasting, memorable performances. Through the mentorship and guidance of former coaches/captains, Gurshaan was able to make the most of his dancing experience, and it is through this same conviction for preserving the art form that he wishes to teach at Bay Area Bhangra Arts."
export const RAMNIK_SINGH = "Ramnik Singh"
export const RAMNIK_BIO = "Ramnik started his bhangra journey alongside his undergraduate career at UC Berkeley with Cal Bhangra in 2018. Since then, he has danced with Cal Bhangra, Hukam de Yakke, Norcal Naujawan, and Gabroo Unit at 11 competitions across North America and has had the privilege of being able to serve as a captain, choreographer, and coach. At Bay Area Bhangra Arts, Ramnik wishes to share his love and respect for Punjabi culture, music, and bhangra’s folk roots as well as extend his passion for fitness and learning with his community."

export const CONTACT_INFO_EMAIL = "bayareabhangraarts@gmail.com"
export const CONTACT_INFO_PHONE = "(408) 834-5147"
export const SOCIALS_TITLE = "Follow us on social media!"
export const INSTAGRAM_LINK = 'https://www.instagram.com/bayareabhangraarts/'
export const YOUTUBE_LINK = 'https://www.instagram.com/bayareabhangraarts/'
export const FACEBOOK_LINK = 'https://www.instagram.com/bayareabhangraarts/'

export const CHOREOGRAPHY_TITLE = "Hire Us"
export const CHOREOGRAPHY_DESCRIPTION = "We are proud to offer completely customized choreography for any mixes you have or have our professional team members perform at your event. This can be for sangeets, receptions, birthday parties, or any other functions you may have. Please provide us with your information and a quick description of your choreography / gig request and we will respond to you within 24 hours.";
export const CHOREOGRAPHY_NAME_PLACEHOLDER = "Name"
export const CHOREOGRAPHY_EMAIL_PLACEHOLDER = "Email Address"
export const CHOREOGRAPHY_DESCRIPTION_PLACEHOLDER = "Description of what choreography / gig you would like to have organized (duration, number of songs, what event this is for, etc)"
export const SUCCESS_MESSAGE = "Your request was successfully sent! We will reach out to you within 24 hours!"
export const ERROR_VALIDATION_MESSAGE = "There was an error with your input. Please make sure you have filled out all of the fields and are only using letters for your name. If this issue persists, please email us directly at " + CONTACT_INFO_EMAIL
export const ERROR_SERVER_MESSAGE = "There was an error while trying to send the email. Please try again or email us directly at " + CONTACT_INFO_EMAIL

export const YOUTUBE_VIDEO_LINKS = [
    {
        link:  "https://www.youtube.com/embed/NBF8ByizJ64",
        title: "Gabroo Unit at Bhangra at the Alamo 2024"
    },
    {
        link:  "https://www.youtube.com/embed/ccuQ2o6ahLs",
        title: "Hukam De Yakke | Back Row | ATL Tamasha 2023"
    },
    {
        link:  "https://www.youtube.com/embed/5yFefbDLl1M",
        title: "Cal Bhangra @ SB Squared (Third Place)"
    }         
]

export const HEADER_TABS = [
    { 
      title: CLASSES,
      url: "#classes"
    }, 
    { 
      title: PERFORMANCES,
      url: "#performances"
    }, 
    { 
      title: ABOUT_US,
      url: "#about-us"
    }, 
    { 
      title: CHOREOGRAPHY,
      url: "#choreography"
    },
    { 
      title: CONTACT_AND_SOCIALS,
      url: "#contact-us-socials"
    }
  ];