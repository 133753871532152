import React from 'react';
import FooterTitle from './FooterTitle';
import { SocialIcon } from 'react-social-icons';
import { FACEBOOK_LINK, INSTAGRAM_LINK, SOCIALS_TITLE, YOUTUBE_LINK } from '../util/constants';

function Socials(props) {
  return (
    <div className='socials-wrapper'>
        <FooterTitle title={SOCIALS_TITLE}/>
        <div>
            <SocialIcon style={{ height: 75, width: 75 }} className='social-icon' bgColor='transparent' fgColor='black' network='instagram' url={INSTAGRAM_LINK}/>
            {/* <SocialIcon style={{ height: 90, width: 90 }} className='social-icon' bgColor='transparent' fgColor='black' network='youtube' url={YOUTUBE_LINK}/> */}
            {/* <SocialIcon style={{ marginLeft: -15, marginTop: -7, paddingLeft: -30, height: 75, width: 75 }} className='social-icon' bgColor='transparent' fgColor='black' network='facebook' url={FACEBOOK_LINK}/> */}
        </div>
    </div>
  );
}

export default Socials;
